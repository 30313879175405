import React, { useEffect, useState } from "react";
import * as d3 from "d3";
import Navbar from "./Navbar";

const Tree = (props) => {
  const [version, setVersion] = useState("4.2");
  const [data, setData] = useState({});

  useEffect(() => {
    if(props.env == 'fashion') {
      if (version == "4") {
        setData(props.datav4);
      } else if (version == "3") {
        if (props.datav3 == undefined || props.datav3 == null)
          setData({ name: "No Version 3" });
        else setData(props.datav3);
      }else if (version == "4.1") {
        if (props.datav4_1 == undefined || props.datav4_1 == null)
          setData({ name: "No Version 4.1" });
        else setData(props.datav4_1);
      }else if (version == "4.2") {
        if (props.datav4_2 == undefined || props.datav4_2 == null)
          setData({ name: "No Version 4.2" });
        else setData(props.datav4_2);
      }
    }else{
      setData(props.data)
    }
  
  }, [props.datav4, props.datav4_1, props.datav4_2, props.datav3, version]);

  let dataTree;
  // const svgRef = useRef();
  let B = document.body,
    H = document.documentElement,
    scaleExtent=[0.6, 3]


  
  // let width = document.documentElement.offsetWidth;
  // let height = document.documentElement.offsetHeight;

  // let width = document.body.offsetWidth;
  // let height = document.body.offsetHeight;

  // let width=document.body.clientWidth;
  // let height = document.body.clientHeight;
  const height = Math.max(
    B.scrollHeight,
    B.offsetHeight,
    H.clientHeight,
    H.scrollHeight,
    H.offsetHeight
  );
  const width = Math.max(
    B.scrollWidth,
    B.offsetWidth,
    H.clientWidth,
    H.scrollWidth,
    H.offsetWidth
  );
  var m = [20,10, 40, 10],
    w = width -m[0] -m[0],
    h = height -m[0] -m[2]
  // let margin = { top: 20, right: 90, bottom: 30, left: 90 };
  let i = 0,
    duration = 300;

  // Collapse the node and all it's children
  const collapse = (d) => {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  };

  // update function
  const update = (source, svg, treeData, root, zoomed) => {
    let levelWidth = [1];
    let childCount = (level, n) => {
      if (n.children && n.children.length > 0) {
        if (levelWidth.length <= level + 1) levelWidth.push(0);
        levelWidth[level + 1] += n.children.length;
        n.children.forEach((d) => {
          childCount(level + 1, d);
        });
      }
    };
    childCount(0, root);
    let newHeight;
    if (height <= 770) {
      if(levelWidth.length == 2)
      newHeight = d3.max(levelWidth) * 12.5
      else  newHeight = d3.max(levelWidth) * 16 ;
    } else if (height <= 910) {
      if(levelWidth.length == 2)
      newHeight = d3.max(levelWidth) * 14
      else  newHeight = d3.max(levelWidth) * 16 
    } else if (height <= 1090) {
      if(levelWidth.length == 2)
      newHeight = d3.max(levelWidth) * 16
      else  newHeight = d3.max(levelWidth) * 17 ;
    }
    var treemap = d3.tree().size([newHeight, width]);
    var treeData = treemap(root);
    // if (height < newHeight) {
    //   d3.select("svg").attr("height", newHeight + margin.top + margin.bottom);
    //   height = newHeight;
    //   centerNode(root, zoomed, height);
    // }
    // new tree layout.
    let nodes = treeData.descendants(),
      links = treeData.descendants().slice(1);

    nodes.forEach(function (d) {
      d.y = d.depth * 180;
    });

    // ****************** Nodes section ***************************
    // Update the nodes...
    var node = svg.selectAll("g.node").data(nodes, function (d) {
      return d.id || (d.id = ++i);
    });

    // Enter any new nodes at the parent's previous position.
    var nodeEnter = node
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", function (d) {
        return "translate(" + source.y0 + "," + source.x0 + ")";
      })
      .on("click", (d) => {
        click(d);
      });

    // Add Circle for the nodes
    nodeEnter
      .append("circle")
      .attr("class", "node")
      .attr("r", 0)
      .style("fill", function (d) {
        return d._children ? " #d4d3ed" : "#52489c";
      })
      .style("stroke", "#52489c");

    // Add labels for the nodes
    nodeEnter
      .append("text")
      .attr("dy", ".35em")
      .attr("x", function (d) {
        return d.depth || d.depth ? 7 : -7;
      })
      .attr("text-anchor", function (d) {
        return d.depth || d.depth ? "start" : "end";
      })
      .text(function (d) {
        return d.data.name;
      })
      .style("fill-opacity", 0);

    // UPDATE
    var nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + d.y + "," + d.x + ")";
      });

    // Update the node attributes and style
    nodeUpdate
      .select("circle.node")
      .attr("r", 4)
      .style("fill", function (d) {
        return d._children ? " #d4d3ed" : "#52489c";
      })
      .attr("cursor", "pointer");

    // Fade the text in
    nodeUpdate.select("text").style("fill-opacity", 1);
    // Remove any exiting nodes
    var nodeExit = node
      .exit()
      .transition()
      .duration(duration)
      .attr("transform", function (d) {
        return "translate(" + source.y + "," + source.x + ")";
      })
      .remove();

    // On exit reduce the node circles size to 0
    nodeExit.select("circle").attr("r", 0);

    // On exit reduce the opacity of text labels
    nodeExit.select("text").style("fill-opacity", 0);

    // ****************** links section ***************************

    // Update the links...
    var link = svg.selectAll("path.link").data(links, function (d) {
      return d.id;
    });

    // Enter any new links at the parent's previous position.
    var linkEnter = link
      .enter()
      .insert("path", "g")
      .attr("class", "link")
      .attr("stroke", ' #d4d3ed')
      .attr("d", function (d) {
        var o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      });

    // UPDATE
    var linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    linkUpdate
      .transition()
      .duration(duration)
      .attr("d", function (d) {
        return diagonal(d, d.parent);
      });

    // Remove any exiting links
    var linkExit = link
      .exit()
      .transition()
      .duration(duration)
      .attr("d", function (d) {
        var o = { x: source.x, y: source.y };
        return diagonal(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    // Creates a diagonal path from parent to the child nodes
    function diagonal(s, d) {
      let path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 1.9} ${s.x},
                ${(s.y + d.y) / 1.7} ${d.x},
                ${d.y} ${d.x}`;

      return path;
    }

    // Toggle children on click.
    const click = (d) => {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d, svg, treeData, root);
      centerNode(d, zoomed);
    };
  };
  // Function to center node when clicked
  const centerNode = (source, zoomed) => {
    // notice x = height
    //        y = width
    let t = d3.zoomTransform(source);
    let x = -source.y0;
    let y = -source.x0;
    x = x * t.k + width / 2;
    y = y * t.k + height / 2;

    d3.select("svg")
      .transition()
      .duration(duration)
      .call(
        d3.zoom().scaleExtent(scaleExtent).on("zoom", zoomed).transform,
        d3.zoomIdentity.translate(x, y).scale(t.k)
      );
    
     if(x!== 0 && y!==0 && t.k!==0 && source.depth !== 0 ){
        d3.select("g.rootG")
        .transition()
        .duration(duration)
        .attr("transform", "translate(" + x + "," + y + ")scale(" + t.k + ")")
        .on('end', function(){
          d3.select("g.rootG").call(d3.zoom().scaleExtent(scaleExtent).on("zoom", zoomed).transform,
          d3.zoomIdentity.translate(x, y).scale(t.k))
          }
      )}
      };

  // Zoom BTN
  function zoomIn(zoomed) {
    d3.select("#zoom_in").on("click", function () {
      d3.zoom()
        .scaleExtent(scaleExtent)
        .on("zoom", zoomed)
        .scaleBy(d3.select("svg").transition().duration(300), 1.1);
    });
  }
  function zoomOut(zoomed) {
    d3.select("#zoom_out").on("click", function () {
      d3.zoom()
        .scaleExtent(scaleExtent)
        .on("zoom", zoomed)
        .scaleBy(d3.select("svg").transition().duration(300), 0.8);
    });
  }

  useEffect(() => {
    dataTree = {};
    d3.select("svg").remove();
    dataTree = data;
    // append the svg object to the body of the page
    let svg = d3
      .select("#tree-container")
      .append("svg")
      .call(d3.zoom().scaleExtent(scaleExtent).on("zoom", zoomed))
      .attr("width", width)
      .attr("height", height)
      .attr("class", "overlay")
      .append("g")
      .attr("class", 'rootG')
    d3.select("svg").on("dblclick.zoom", null);

    function zoomed() {
      // svg.attr("transform", d3.event.transform);
      let scale = d3.event.transform.k,
      translation = [d3.event.transform.x, d3.event.transform.y] ,
      tbound = -h/2 * scale,
      bbound = h * scale,
      lbound = (-w + m[1]) * scale,
      rbound = (w - m[3]) * scale;
  // limit translation to thresholds
     let x,y
      if(d3.event.transform.x >40  ){
        x=Math.max(Math.min(translation[0], rbound), lbound)
      }else{ x=40 }
      if(d3.event.transform.y >-1 || root._children==null){
        y= Math.max(Math.min(translation[1], bbound), tbound)
      }else{ y= -1 }
      translation = [x,y];
      d3.select(".rootG") 
          .attr("transform", "translate(" + translation + ")" +
                " scale(" + scale + ")");
    }
    // zoom BTN
    zoomIn(zoomed);
    zoomOut(zoomed);

    // Assigns parent, children, height, depth
    let root = d3.hierarchy(dataTree, function (d) {
      return d.children;
    });
    // declares a tree layout and assigns the size
    var treemap = d3.tree().size([height, width]);
    var treeData = treemap(root);
    //form x and y axis
    root.x0 = height / 2;
    root.y0 = 0;
    // Collapse
    collapse(root);
    update(root, svg, treeData, root, zoomed);
    centerNode(root, zoomed);
  }, [data]);
  return (
    <div id="tree-container">
      {props.env =='fashion'? <Navbar setData={setData} version={version} setVersion={setVersion} /> :null }
      <div className=" zoom">
        <div id="zoom_in" className="zoom-item">
          +
        </div>
        <div id="zoom_out" className="zoom-item">
          -
        </div>
      </div>
    </div>
  );
};

export default Tree;
