import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import conf from './config.json'
import dataEn from "./assets/fashion/en/template_taxonomy_v4.0.json"
import dataEn4_1 from "./assets/fashion/en/template_taxonomy_v4.1.json"
import dataEn4_2 from "./assets/fashion/en/template_taxonomy_v4.2.json"
import dataMk from "./assets/fashion/mk/mk_template_v4.0.json";
import dataEnv3 from "./assets/fashion/en/templateTaxonomy.json"
import dataMkv3 from "./assets/fashion/mk/mk_template.json"
import dataHomewareFurniture from "./assets/homeware and furniture/template_taxonomy_h_and_f_v1.0.json"
import Tree from "./components/Tree";

function App() {
  
  const fashion = conf.fashion.build
  const hf = conf.hf.build
  // set dynamically meta title / description
  useEffect(()=>{
    document.title = fashion == 'true'? "Pixyle.ai | Fashion Taxonomy":"Pixyle.ai | Homeware and Furniture Taxonomy"
    
    document.getElementsByTagName("META")[2].content=
    fashion == 'true'? "Pixyle.ai | Fashion Taxonomy":"Pixyle.ai | Homeware and Furniture Taxonomy"
  }, [])
  return (
    <div className="App">
      <Router>
        <div>
        {fashion === 'true' ?
          <Routes>
            <Route exact path="/" element={<Navigate to="/en" />}/>
            <Route path="/en" element={<Tree datav4={dataEn} datav3={dataEnv3} datav4_1={dataEn4_1} datav4_2={dataEn4_2} env="fashion"/>} />
            <Route path="/mk" element={<Tree datav4={dataMk} datav3={dataMkv3} env="fashion"/>} />
          </Routes> : hf === 'true' ?
          <Routes>
            <Route exact path="/" element={<Tree data={dataHomewareFurniture} env="hf" />} />
          </Routes> : null
          }
        
         
        </div>
      </Router>
    </div>
  );
}

export default App;
