import React, {useState} from "react";
import { NavLink } from "react-router-dom";

const Navbar = ({setData, version, setVersion}) => {
    let activeClassName = "active"
    const onChangeVersion = (e) => {
        setData({});
        setVersion(e.target.value);
    }
    return (
        <ul className="nav" >
            {/* <div className="navbar">
                <li className="nav-item">
                    <NavLink to="/en" className="li-item link"
                        className={({ isActive }) =>
                            isActive ? activeClassName : ''
                        } >English</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/mk" className="li-item link" className={({ isActive }) =>
                        isActive ? activeClassName : ''
                    } >Macedonian</NavLink>
                </li>
            </div> */}
            <div className="navbar">
                <form>
                    <label htmlFor="version"></label>
                    <select id="version" className="option" name="version" value={version} onChange={(e)=> onChangeVersion(e) }>
                        <option className="option" value="4.2">V4.2</option>
                        <option className="option" value="4.1">V4.1</option>
                        <option className="option" value="4" >V4</option>
                        <option  className="option" value= "3"> V3 </option>
                    </select>
                </form>
            </div>

        </ul>
    )
}
export default Navbar;